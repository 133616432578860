import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b03a206"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contain" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "contain-title" }
const _hoisted_4 = { class: "contain-info" }
const _hoisted_5 = { class: "userName" }
const _hoisted_6 = { class: "time" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "contain-img",
      src: _ctx.info.img,
      alt: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.info.title), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.info.author), 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.info.publish_time), 1)
    ]),
    _createElementVNode("div", {
      id: "contain-content",
      innerHTML: _ctx.info.content,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getTag && _ctx.getTag(...args)))
    }, null, 8, _hoisted_7)
  ]))
}