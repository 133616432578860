import type {
  PoolInfo,
  ListPoolsParams,
  PoolDetailInfo,
  PoolInfoForEdit,
  GoodsInfo,
  PoolGoodsInfo,
  AddGoodsParams,
  CreatePoolParams,
  EditPoolParams,
  ApplyPoolInfoForLeader,
  ApplyPoolInfoForMember,
  PoolUserInfo,
  dealPoolParams,
  FavPoolInfo
} from '@/types/pool';

import request from "@/utils/request";
import { htmlDecode, strip } from '@/utils/tools';
import dayjs from 'dayjs';

// 获取拼邮列表
function listHotPools(params: ListPoolsParams) {
  function formatPoolList(data: Array<any>): Array<PoolInfo> {
    return data.map((item) => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        desc: strip(htmlDecode(item?.description)) ?? '',
        expiryDate: item?.deadline ?? '',
        type: item?.type ?? '',
        express: item?.express_id ?? '', 
        delivery: item?.delivery ?? '', // 交货方式
        currentWeight: item?.weight_count ?? 0, // 当前重量
        maxWeight: item?.real_weight ?? 0,
        leaderName: item?.user_name ?? '',
        leaderHeadUrl: item?.head_url ?? 'https://poipoibox.blob.core.windows.net/oss/system/images/default-user-3x.png',
        areaIds: item?.area_ids ?? '',
        likeCount: item?.likeCount ?? 0, // 收藏人数
        participants: item?.usersNum ?? 0, // 参与人数
      }
    });
  }
  return request.post("/supplier/pool/index", {
    page: params.page,
    keyword: params.keyword,
    order_type: params.orderType,
    area_ids: params.areaIds,
    express_id: params.expressIds,
    pool_status: params.poolStatus,
    deadline: params.expiryDate,
    type: params.type,
  }).then(res => {
    return {
      poolList: formatPoolList(res.data?.pools ?? []),
      total: res.data?.total ?? 0
    }
  });
}

// 获取拼邮详情
const getPoolDetailInfo = (params: { poolId: number }) => {
  function formatPoolInfo(data: any): PoolDetailInfo {
    return {
      id: data.pool?.pool_id,
      name: data.pool?.name ?? '',
      status: data.pool?.status ?? -1,
      statusName: data.pool?.statusName ?? '',
      leaderName: data.pool?.poolUser?.name ?? '',
      leaderHeadUrl: data.pool?.poolUser?.head_url ?? '',
      expiryDate: data.pool?.expiry_date ?? '',
      areaText: data.pool?.area_ids ?? '',
      expressName: data.pool?.express_id ?? '',
      estimateRateText: data.pool?.estimate_rate ?? '',
      maxWeight: +data.pool?.real_weight ?? 0,
      currentWeight: +data.pool?.weight_count ?? 0,
      delivery: data.pool?.delivery ?? '',
      supportType: data.pool?.type ?? '',
      description: data.pool?.description ?? '',
      subscribed: data.pool?.hasLike ?? false,
      participants: data.pool?.joinCount ?? 0,
      favorites: data.pool?.likeCount ?? 0
    };
  }
  return request
    .post('/supplier/pool/detail', {
      pool_id: params.poolId
    })
    .then((res) => {
      return formatPoolInfo(res.data);
    });
};

const getPoolInfoForEdit = (params: { poolId: string }) => {
  function formatInfo(data: any): PoolInfoForEdit {
    // 服务端为了防错，非中国大陆地区的地址，三级行政区会设置成5000
    const areaIds = data.area_ids.split(',').map((item, index) => {
      if (+item === 0 || (+item === 5000 && index === 2)) return '';
      return item;
    });
    const expiryDate =
      data.deadline ? dayjs.unix(data.deadline).format('YYYY-MM-DD') : ''
    const description = htmlDecode(data.description ?? '');
    return {
      id: data.pool_id,
      name: data.name ?? '',
      areaIds,
      expressId: data.express_id ?? '',
      type: `${data.type ?? ''}`,
      estimateRate: data.estimate_rate ?? '',
      maxWeight: data.real_weight ?? 0,
      delivery: `${data.delivery ?? ''}`,
      expiryDate,
      privacy: `${data.privacy ?? ''}`,
      phone: data.phone,
      email: data.email,
      description,
      editCount: data.edit_time ?? 0
    };
  }
  return request
    .post('/supplier/pool/info', {
      pool_id: params.poolId
    })
    .then((res) => {
      return formatInfo(res.data);
    });
}

// 获取库存商品
const listGoodsForShipping = (params: { poolId: number }) => {
  function formatGoodsList(data: any): Array<GoodsInfo> {
    return ((Array.isArray(data) && data) || []).map((item: any): GoodsInfo => {
      return {
        id: item?.goods_id ?? 0,
        siteIcon: item?.site_icon ?? '',
        shopName: item?.shop_name ?? '',
        shelf: item?.shelf ?? '',
        productTitle: item?.product_title ?? '',
        productType: item?.product_type ?? '',
        priceJPY: +item?.unit_price ?? 0,
        priceRMB: +item?.unit_price_rmb ?? 0,
        amount: +item?.amount ?? 0,
        weight: +item?.weight ?? 0
      };
    });
  }
  function formatPoolInfo(pool: any, userId: number) {
    return {
      isPoolLeader: pool?.user_id === userId ?? false,
      maxWeight: +pool?.real_weight ?? 0, // 目标重量
      currentWeight: +pool?.weight_count ?? 0, // 当前重量
      estimateRate: +pool?.estimate_rate ?? 0
    };
  }
  return request
    .post('/supplier/pool/applyPartake', {
      pool_id: params.poolId
    })
    .then((data) => {
      return {
        goodsList: formatGoodsList(data.data?.shelves?.allowed),
        poolInfo: formatPoolInfo(data.data?.pool, data.data?.user?.user_id)
      };
    });
};

// 添加商品到拼邮中
const addGoodsToPool = (params: AddGoodsParams) => {
  return request
    .post('/supplier/pool/addPoolGoods', {
      pool_id: `${params.poolId}`,
      shelf: params.shelves,
      phone: params.phone,
      email: params.email,
      message: params.message
    })
    .then((res: any) => {
      const data = res.data;
      if (data.id && res.msg) {
        return res.msg;
      }
      return '';
    });
};
// 获取拼邮初始化信息
const getBasicInfo = () => {
  function formatExpressOptions(data: Array<any>) {
    return data.map(item => {
      return {
        name: item?.name,
        value: `${item?.express_id}`
      }
    });
  }
  function formatCategoryOptions(data: Array<any>) {
    return data.map(item => {
      return {
        name: item?.name,
        value: `${item?.type_id}`
      }
    })
  }
  function formatDeliveryOptions(data: Array<any>) {
    return data.map(item => {
      return {
        name: item?.name,
        value: `${item?.delivery_id}`
      }
    })
  }
  return request.post("/supplier/pool/basicInfo").then(res => {
    return {
      expressOptions: formatExpressOptions(Object.values(res.data.express) || []),
      categoryOptions: formatCategoryOptions(Object.values(res.data.category) || []),
      deliveryOptions: formatDeliveryOptions(res.data.delivery || []),
      minPoolWeight: res.data?.min_pool_weight ?? 0,
      maxPoolWeight: res.data?.max_pool_weight ?? 0,
      limitEditCount: res.data?.pool_limit_edit ?? 0
    }
  });
};

// 获取地区列表
const getAreaList = () => {
  function formatList(data: Array<any>) {
    return data.map(item => {
      return {
        id: item?.area_id,
        name: item?.name,
        parentId: item?.parent_id ?? 0
      }
    })
  }
  return request.post('/supplier/pool/getAreaList').then(res => {
    return {
      areaList: formatList(Object.values(res.data) || [])
    }
  });
};

// 获取可用物流
const getAvailableExpress = (params: { areaId: string }) => {
  return request.post("/supplier/pool/getAvailableExpress", {
    area_id: params.areaId
  }).then(res => {
    return {
      expressIds: res?.data.express_ids ?? [],
      weightRecord: res?.data.weight_rates ?? {}
    }
  });
};

// 创建拼邮
const createPool = (params: CreatePoolParams) => {
  return request.post("/supplier/pool/addPool", {
    name: params.name,
    area_ids: params.areaIds,
    express_id: params.expressId,
    type: params.type,
    estimate_rate: params.estimateRate,
    real_weight: params.realWeight,
    delivery: params.delivery,
    privacy: params.privacy,
    deadline: params.deadline,
    phone: params.phone,
    email: params.email,
    description: params.description
  }).then(res => {
    return {
      poolId: res?.data.pool_id ?? -1
    }
  });
};

const editPool = (params: EditPoolParams) => {
  return request.post("/supplier/pool/edit", {
    pool_id: params.poolId,
    name: params.name,
    area_ids: params.areaIds,
    express_id: params.expressId,
    // type: params.type,
    estimate_rate: params.estimateRate,
    real_weight: params.realWeight,
    delivery: params.delivery,
    privacy: params.privacy,
    deadline: params.deadline,
    phone: params.phone,
    email: params.email,
    description: params.description
  }).then(res => {
    return res.data?.pool_id ?? -1
  })
}

const deletePool = (params: { poolId: string }) => {
  return request.post("/supplier/pool/delete", {
    pool_id: params.poolId
  }).then(() => {
    return true;
  })
}

const searchPoolByCode = (params: { code: string }) => {
  return request
    .post('/supplier/pool/searchWithInviteCode', {
      invite_code: params.code
    })
    .then((res) => {
      if (res.data.pool_id) {
        return res.data.pool_id;
      }
      return -1;
    });
};

// 拼邮长 - 处理拼邮申请
const listPendingApplyPools = (params: { page: number }) => {
  function formatUserList(data: Array<any>): Array<PoolUserInfo> {
    return data.map(item => {
      return {
        id: item?.user_id ?? 0,
        name: item?.user_name ?? '',
        applyTime: item?.apply_time ?? '',
        weightText: item?.weight_count ?? '',
        amount: item?.goods_count ?? 0
      }
    })
  }
  function formatPoolList(data: Array<any>): Array<ApplyPoolInfoForLeader> {
    return data.map(item => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        expiryDate: item?.deadline ?? '',
        currentWeight: item?.weight_count ?? 0, // 当前重量
        maxWeight: item?.real_weight ?? 0,
        hasFinished: false,
        users: formatUserList(item?.poolUsers ?? []),
        editCount: 0
      }
    });
  }
  return request
    .post("/supplier/pool/pendingApplyForLeader", {
      page: params.page
    }).then((res) => ({
      poolList: formatPoolList(res.data?.pools ?? []),
      total: res.data?.total ?? 0
    }));
};

// 拼邮长 - 查看申请商品
const listGoodsForApply = (params: { poolId: number; userId: number; allowedStatus: 0|1 }) => {
  function formatGoodsList(data: Array<any>): Array<PoolGoodsInfo> {
    return data
      .filter(item => item.status === params.allowedStatus)
      .map(item => {
        return {
          id: item?.goods_id ?? 0,
          title: item?.title ?? '',
          link: item?.link ?? '',
          weight: item?.weight ?? 0,
          amount: item?.amount ?? 0,
          priceText: item?.price ?? '',
          type: item?.product_type ?? ''
        }
      });
  }
  return request.post("/supplier/pool/getGoodsForApply", {
    pool_id: params.poolId,
    apply_uid: params.userId
  }).then((res) => ({
    goodsList: formatGoodsList(res.data?.poolGoods ?? []),
    message: res.data?.message ?? '',
    userName: res.data?.user?.name ?? ''
  }));
};

// 拼邮长 - 处理拼邮商品（通过/拒绝）
const dealPoolForApply = (params: dealPoolParams) => {
  return request.post<never, any>("/supplier/pool/dealPoolForApply", {
    pool_id: params.poolId,
    apply_uid: params.userId,
    type: params.type
  }).then((res) => {
    return res.msg ?? ''
  });
};

// 拼邮长 - 移除拼邮商品
const removeGoodsFromPool = (params: { poolId: number; goodsId: number}) => {
  return request.post<never, any>('/supplier/pool/removePoolGoods', {
    pool_id: params.poolId,
    goods_id: params.goodsId
  }).then((res) => {
    return res.msg ?? '';
  });
};

// 拼邮长 - 进行中的拼邮
const listOngoingPoolsForLeader = (params: ListPoolsParams) => {
  function formatUserList(data: Array<any>): Array<PoolUserInfo> {
    return data.map(item => {
      return {
        id: item?.user_id ?? 0,
        name: item?.user_name ?? '',
        applyTime: item?.apply_time ?? '',
        weightText: item?.weight_count ?? '',
        amount: item?.goods_count ?? 0
      }
    })
  }
  function formatPoolList(data: Array<any>): Array<ApplyPoolInfoForLeader> {
    return data.map(item => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        expiryDate: item?.deadline ?? '',
        currentWeight: item?.weight_count ?? 0, // 当前重量
        maxWeight: item?.real_weight ?? 0,
        hasFinished: item?.status !== 1 ?? false,
        users: formatUserList(item?.poolUsers ?? []),
        usersNumText: `${item?.usersNum ?? ''}`,
        goodsNumText: `${item?.goodsNum ?? ''}`,
        privacyText: ['公开', '私人拼邮'][item?.privacy ?? 0],
        inviteCode: item?.invite_code ?? '',
        editCount: item?.edit_time ?? 0
      }
    });
  }
  return request.post("/supplier/pool/ongoingPoolForLeader", {
    page: params.page,
    keyword: params.keyword,
    order_type: params.orderType,
    express_id: params.expressIds,
    pool_status: params.poolStatus
  }).then((res) => ({
    poolList: formatPoolList(res.data?.pools ?? []),
    total: res.data?.total ?? 0
  }));
};

// 拼邮长 - 已完成的拼邮
const listFinishedPoolsForLeader = (params: ListPoolsParams) => {
  function formatUserList(data: Array<any>): Array<PoolUserInfo> {
    return data.map(item => {
      return {
        id: item?.user_id ?? 0,
        name: item?.user_name ?? '',
        applyTime: item?.apply_time ?? '',
        weightText: item?.weight_count ?? '',
        amount: item?.goods_count ?? 0
      }
    })
  }
  function formatPoolList(data: Array<any>): Array<ApplyPoolInfoForLeader> {
    return data.map(item => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        expiryDate: item?.deadline ?? '',
        currentWeight: item?.weight_count ?? 0, // 当前重量
        maxWeight: item?.real_weight ?? 0,
        hasFinished: item?.status !== 1 ?? false,
        users: formatUserList(item?.poolUsers ?? []),
        usersNumText: `${item?.usersNum ?? ''}`,
        goodsNumText: `${item?.goodsNum ?? ''}`,
        totalWeightText: item?.real_weight ? `${item.real_weight}g` : '',
        editCount: 0
      }
    });
  }
  return request.post("/supplier/pool/finishedPoolForLeader", {
    page: params.page,
    keyword: params.keyword,
    order_type: params.orderType,
    express_id: params.expressIds,
    ship_status: params.shipStatus // 31: 待发货, 32: 已发货
  }).then((res) => ({
    poolList: formatPoolList(res.data?.pools ?? []),
    total: res.data?.total ?? 0
  }));
};

// 拼邮长 - 现在截团
const finishPool = (params: { poolId: number }) => {
  return request.post<never, any>("/supplier/pool/editPoolDeadline", {
    pool_id: params.poolId
  }).then((res) => {
    return res.msg ?? '';
  });
};

// 拼邮长 - 现在发货
const deliverPool = (params: { poolId: number }) => {
  return request.post("/supplier/pool/deliverPool", {
    pool_id: params.poolId
  }).then((res) => {
    return res.data ?? {};
  });
};

// 拼邮员 - 已完成的拼邮
const listFinishedPoolsForMember = (params: ListPoolsParams) => {
  function formatGoodsList(data: Array<any>): Array<PoolGoodsInfo> {
    return data.map(item => {
      return {
        id: item?.goods_id ?? 0,
        title: item?.title ?? '',
        link: item?.link ?? '',
        weight: item?.weight ?? 0,
        amount: item?.amount ?? 0,
        priceText: item?.price ?? '',
        type: item?.product_type ?? ''
      }
    })
  }
  function formatPoolList(data: Array<any>): Array<ApplyPoolInfoForMember> {
    return data.map(item => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        expiryDate: item?.deadline ?? '',
        currentWeight: item?.weight_count ?? 0, // 当前重量
        maxWeight: item?.real_weight ?? 0,
        hasFinished: item?.status !== 1 ?? false,
        leaderName: item?.poolUser?.name ?? '',
        applyData: item?.apply_time ?? '',
        goods: formatGoodsList(item?.poolGoods ?? []),
      }
    });
  }
  return request.post("/supplier/pool/finishedPoolForMember", {
    page: params.page,
    keyword: params.keyword,
    order_type: params.orderType,
    express_id: params.expressIds,
    ship_status: params.shipStatus // 31: 待发货, 32: 已发货
  }).then((res) => ({
    poolList: formatPoolList(res.data?.pools ?? []),
    total: res.data?.total ?? 0
  }));
};

// 拼邮员 - 参与中的拼邮
const listOngoingPoolsForMember = (params: ListPoolsParams) => {
  function formatGoodsList(data: Array<any>): Array<PoolGoodsInfo> {
    return data.map(item => {
      return {
        id: item?.goods_id ?? 0,
        title: item?.title ?? '',
        link: item?.link ?? '',
        weight: item?.weight ?? 0,
        amount: item?.amount ?? 0,
        priceText: item?.price ?? '',
        type: item?.product_type ?? ''
      }
    })
  }
  function formatPoolList(data: Array<any>): Array<ApplyPoolInfoForMember> {
    return data.map(item => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        expiryDate: item?.deadline ?? '',
        currentWeight: item?.weight_count ?? 0, // 当前重量
        maxWeight: item?.real_weight ?? 0,
        hasFinished: item?.status !== 1 ?? false,
        leaderName: item?.poolUser?.name ?? '',
        applyData: item?.apply_time ?? '',
        goods: formatGoodsList(item?.poolGoods ?? [])
      }
    });
  }
  return request.post("/supplier/pool/ongoingPoolForMember", {
    page: params.page,
    keyword: params.keyword,
    order_type: params.orderType,
    express_id: params.expressIds,
    pool_status: params.poolStatus
  }).then((res) => ({
    poolList: formatPoolList(res.data?.pools ?? []),
    total: res.data?.total ?? 0
  }));
};

// 拼邮员 - 撤销拼邮申请
const rescindPoolApply = (params: { poolId: number }) => {
  return request.post<never, any>("/supplier/pool/rescindPoolApply", {
    pool_id: params.poolId
  }).then((res) => {
    return res.msg ?? '';
  });
};

// 收藏拼邮列表
const listFavPools = (params: ListPoolsParams) => {
  function formatPoolList(data: Array<any>): Array<FavPoolInfo> {
    return data.map(item => {
      return {
        id: item?.pool_id ?? 0,
        name: item?.name ?? '',
        leaderName: item?.user_name ?? '',
        joinDate: item?.add_time ?? ''
      }
    });
  }
  return request.post("/supplier/pool/favoritePool", {
    page: params.page,
    keyword: params.keyword,
    order_type: params.orderType,
    express_id: params.expressIds,
    pool_status: params.poolStatus
  }).then((res) => ({
    poolList: formatPoolList(res.data?.pools ?? []),
    total: res.data?.total ?? 0
  }));
};

// 拼邮员 - 取消拼邮收藏
const delFavPool = (params: { poolId: number }) => {
  return request.post<never, any>("/supplier/pool/delFavoritePool", {
    pool_id: params.poolId
  }).then((res) => {
    return res.msg ?? '';
  });
};

export default {
  listHotPools,
  getPoolDetailInfo,
  getPoolInfoForEdit,
  listGoodsForShipping,
  addGoodsToPool,
  getBasicInfo,
  getAreaList,
  getAvailableExpress,
  createPool,
  editPool,
  deletePool,
  searchPoolByCode,
  listPendingApplyPools,
  listGoodsForApply,
  dealPoolForApply,
  removeGoodsFromPool,
  listOngoingPoolsForLeader,
  listFinishedPoolsForLeader,
  listOngoingPoolsForMember,
  listFinishedPoolsForMember,
  finishPool,
  deliverPool,
  rescindPoolApply,
  listFavPools,
  delFavPool
}