import bus from './bus'
  const CallApp = require('callapp-lib')
function globalToDownLoad (){
  this.$router.push('/download')
}
function globalOpenAdd(data){
  const ua = window.navigator.userAgent.toLowerCase();
  if(ua.indexOf('micromessenger') != -1 || ua.includes('weibo')){
    bus.$emit("showBrowserModel",'')
    return
  } 
  // qq内打开，不包含qq浏览器
  if(ua.indexOf('qq') != -1 && !ua.includes('mqqbrowser')){
    bus.$emit("showBrowserModel",'')
    return
  }
  const isIOS = !ua.includes("android");
    const options = {
      scheme: {
        // protocol: 'ccat',
        protocol: isIOS ? 'rennigoulite' : `rnglite://${process.env.VUE_APP_ANDROID_HOST}`
      },
       appstore: 'https://itunes.apple.com/cn/app/id1559870015?mt=8',
       fallback: `${window.location.origin}/download`
    };
    const callLib = new CallApp(options)
    callLib.open({
      param: data?.params || {},
      path: data?.path || '' ,
    });
}
export default {
    globalAmountRule : (amount)=>{
        let defaultAmount = ' '
        let setAmount = amount + ' '
        if(setAmount != 'null' && setAmount != '' && setAmount != 'undefined' && setAmount != '--'){
          if(setAmount.indexOf('.') !== -1) {
            defaultAmount = Number(setAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
          } else {
             defaultAmount = Number(setAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','')
          }
          return defaultAmount
        }else{
          return  defaultAmount
        }
      },
    globalDebounce: (fun, delay) =>{
        return function (...args) {
            clearTimeout(fun.id)
            fun.id = setTimeout(() => {
                fun.call(this, args)
            }, delay)
        }
    },
    globalOrderStatusTime: (time)=>{
      let times = new Date(Number(time) *1000)
      let y = times.getFullYear()
      let m = (times.getMonth() + 1) < 10 ? '0' + (times.getMonth() + 1) : times.getMonth() + 1
      let d = times.getDate() < 10 ? '0'+ times.getDate() : times.getDate()
      let h =  times.getHours() < 10 ? '0'+ times.getHours() : times.getHours() 
      let f =  times.getMinutes() < 10 ? '0'+ times.getMinutes() : times.getMinutes() 
      return `${y}-${m}-${d} ${h}:${f}`
    },
    globalToDownLoad,
    globalOpenAdd,
    globalBus:()=> bus


}