import { createStore } from 'vuex';
import webview from './webview';
import pool from './pool';

export default createStore({
  state: {
    url: process.env.VUE_APP_API_URL,
    wechatUrl: process.env.VUE_APP_WECHAT_API_URL,
    orderInfo: {},
    orderParams:{}
  },
  mutations: {
    set_orderParams: (state:any,info)=>{
     state.orderParams = info
    },
    set_orderInfo: (state:any,info)=>{
      state.orderInfo = info
     },
  },
  actions: {
  },
  modules: {
    webview,
    pool
  },
  getters: {
    get_orderInfo: state =>{

      return state.orderInfo
    }
  }
});
