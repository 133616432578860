declare global {
  interface Window {
    android: {
      getToken: Function,
      getUser: Function,
      close: Function
    },
    webkit: {
      messageHandlers: {
        [key: string]: { postMessage: Function }
      }
    }
  }
}
// 安卓获取用户登录信息
export const getUserInfo = () => {
  if (window.android && window.android.getToken && window.android.getUser) {
    return {
      token: window.android.getToken(),
      userId: JSON.parse(window.android.getUser()).user_id
    }
  }
  if (window?.webkit?.messageHandlers) {
    // IOS会将uid和token拼接在URL上
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    if (searchParams.get('token') && searchParams.get('uid')) {
      return {
        token: searchParams.get('token'),
        userId: searchParams.get('uid')
      }
    }
  }
  return {};
};
// 关闭webview
export const closeWebview = () => {
  if (window?.android?.close) {
    return window.android.close();
  }
  if (window?.webkit?.messageHandlers?.dellocVc) {
    return window.webkit.messageHandlers.dellocVc.postMessage({});
  }
  window.history.back();
};

