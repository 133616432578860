<template>
  <div>
    <BrowserModelVue :show="showModel" @changeShow="changeShowHandle"></BrowserModelVue>
  <router-view/>
  </div>
</template>
<script>
import { defineComponent,onMounted,ref,getCurrentInstance} from 'vue'
import BrowserModelVue from '@/components/shopping/BrowserModel.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components:{
    BrowserModelVue
  },
  setup() {
    const {proxy:_this} = getCurrentInstance()
    const showModel = ref(false)
    const router = useRouter()
    router.beforeEach((to, from, next) => {
      window.document.title = to.meta.title || '2POI'
      next()
      if(to.fullPath.includes('shopping')){
        document.oncontextmenu=function(e){
          e.preventDefault();
        };
      }
    })
    onMounted(()=>{
      _this.globalBus().$on("showBrowserModel",()=>{
        showModel.value = true
      });
    })
    const changeShowHandle = (data)=>{
        showModel.value = data
      } 
    return {
      showModel,
      changeShowHandle
    }

  },
})
</script>
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
