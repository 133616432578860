<template>
  <div class="flex">
    <!-- <h3 class="header">雅虎日拍出价规则</h3> -->
    <div class="content">
      <p>最小加价单位：</p>
      <p>与一般理解的拍卖概念不同，在雅虎上进行竞拍时，您可以直接以<span class="red">【可接受的最高心理价位】</span>进行出价。出价后雅虎日拍并不会立刻将拍卖当前价格提升至您的出价金额，而是提升至【拍卖当前价格+对应最小加价单位】；如您出价后有其他人竞价，系统会自动替您加价，直至拍卖价格超过您的出价。</p>
      <p class="item"><span class="red">*拍卖当前价格与对应的最小加价单位区间对应如下，每次加价都必须≥最小加价单位： </span></p>
      <p class="item">举例：根据出价机制，若某件商品的拍卖当前价格是 5,000 日元，在没有他人竞价的情况下，您出价 30,000 日元后，当前价格将会变为 5,000+250=5,250 日元，而不是直接变为 30,000 日元 。</p>
      <p class="item">雅虎每次出价会有一个最小加价单位，当前价格与最小加价单位对应区间如下：</p>
      <table>
        <tr>
          <td>当前价格（日元）</td>
          <td>最小加价单位（日元）</td>
        </tr>
        <tr>
          <td>1-999</td>
          <td>10</td>
        </tr>
        <tr>
          <td>1,000-4,999</td>
          <td>100</td>
        </tr>
        <tr>
          <td>5,000-9,999</td>
          <td>250</td>
        </tr>
        <tr>
          <td>10,000-49,999</td>
          <td>500</td>
        </tr>
        <tr>
          <td>>50,000</td>
          <td>1,000</td>
        </tr>
      </table>
    </div>
    <!-- <button class="btn" @click="confirm">确认</button> -->
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  setup() {
    // const ua = window.navigator.userAgent;
    // const isIOS = !ua.includes("Android");
    // const confirm = () => {
    //   if (isIOS) {
    //     window.webkit.messageHandlers.jsToOcNoPrams.postMessage({ type: 0 });
    //   } else {
    //     window.jsBridge.close();
    //   }
    // }
    // return {
    //   confirm
    // }
  }
})
</script>
<style lang="scss" scoped>
.flex{
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.header{
  text-align:center;margin-bottom:10px;flex-shrink: 0;
}
.content{
  overflow-y: auto;
}
.red{
  color: red;
}
.item{
  margin-top: 15px;
}
table{
  margin-top: 10px;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  td{
    border: 1px solid;
    padding: 5px;
  }
}
.btn{
  margin-top: 20px;
  color: #fff;
  border: 0;
  background-color: #FF6A5B;
  height: 40px;
  border-radius: 20px;
}
</style>