import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import article from "@/views/article/index.vue";
import qrCode from "@/views/wechat/qrCode.vue";
import mobileCaptcha from "@/views/captcha/mobile.vue";
import download from "@/views/download/download.vue";
import inviteNew from "@/views/invite_new/index.vue";
import inviteNewRecord from "@/views/invite_new/record.vue";
import yahooRule from "@/views/ruler/yahoo.vue";
import pool from './pool';

const routes: RouteRecordRaw[] = [
  // {
  //   path: '/',
  // },
  ...pool,
  {
    path: "/article/:id",
    name: "article",
    component: article,
  },
  {
    path: "/qrCode",
    name: "qrCode",
    component: qrCode,
  },
  // {
  //   path: "/mobile/captcha",
  //   name: "mobileCaptcha",
  //   component: mobileCaptcha,
  // },
  {
    path: "/download",
    name: "download",
    component: download,
    meta: {
      title: "poipoi下载",
    },
  },
  {
    path: "/invite_new",
    name: "invite_new",
    component: inviteNew,
    meta: {
      title: "邀新活动",
    },
  },
  {
    path: "/invite_new_record",
    name: "invite_new_record",
    component: inviteNewRecord,
    meta: {
      title: "邀新活动记录",
    },
  },
  {
    path: "/yahoo_rule",
    name: "yahoo_rule",
    component: yahooRule,
  },
  {
    path: "/shopping/detail/:site/:id",
    name: "detail",
    component: () => import("@/views/shopping/detail.vue"),
    meta: {
      title: "商品详情",
    },
  },
  {
    path: "/shopping/login",
    name: "login",
    component: () => import("@/views/shopping/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/shopping/paydetail",
    name: "paydetail",
    component: () => import("@/views/shopping/payDetail.vue"),
    meta: {
      title: "萌购任你购精品推荐",
    },
  },
  {
    path: "/shopping/orderdetail/:service/:id",
    name: "orderdetail",
    component: () => import("@/views/shopping/orderDetail.vue"),
    meta: {
      title: "萌购任你购 订单查询",
    },
  },
  {
    path: "/shopping/paystatus/:id",
    name: "paystatus",
    component: () => import("@/views/shopping/payStatus.vue"),
    meta: {
      title: "萌购任你购 支付结果",
    },
  },
  {
    path: "/mobileHelps",
    name: "mobileHelps",
    component: () => import("@/views/help/list.vue"),
    meta: {
      title: "帮助列表",
    },
  },
  {
    path: "/help/:key",
    name: "helpDetail",
    component: () => import("@/views/help/detail.vue"),
    meta: {
      title: "帮助详情",
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
