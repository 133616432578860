
import { reactive, toRefs,defineComponent} from 'vue'
import { Overlay } from 'vant';
export default defineComponent({
  name: 'App',
  props:{
    show: {
      default: false,
      type: Boolean
    }
  },
  components:{
    [Overlay.name]: Overlay
  },
  setup (props,{emit}) {
    const changeShow = ()=>{
      emit('changeShow',false)
    }
    return {
     changeShow
    }
  },
})

