import axios from 'axios'
import { Toast } from 'vant';

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    packagePlatform: 'WEB',
    packageVersion: '1.0.9'
  }
});

request.interceptors.request.use(
  (config) => {
    const userInfo =
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo') as string);
    if (userInfo && config.headers) {
      config.headers['token'] = userInfo.token;
      config.headers['uid'] = userInfo.user_id;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    if (
      response.status === 200 &&
      [10000, 1000, 0].includes(response.data.code)
    ) {
      return Promise.resolve(response.data);
    } else if (response.status === 200 && response.data.code != 1001) {
      Toast(response.data.msg);
      return Promise.reject(response.data);
    } else if (response.status == 200 && response.data.code == 1001) {
      // 接口返回未登录状态
      localStorage.clear();
      return Promise.reject((response.data && response.data.msg) || '请求失败');
    } else {
      Toast(response.data || response);
      return Promise.reject(response.data || response);
    }
  },
  error => {
    Toast(error.msg || error.message || error);
    return Promise.reject(error);
  }
);

export default request;
