<template>
  <div class="invite">
    <img class="invite_bg" src="@/assets/img/invite/bg.png" />
    <img class="banner" src="@/assets/img/invite/banner.png" />
    <img class="small-img" :src="info.imgUrl" />
    <span class="invite_code">{{info.msg}}</span>
    <div class="btn1" @click="copy"></div>
    <div class="btn2" @click="visible = true"></div>
    <div class="btn3" @click="jump"></div>
    <ul class="list">
      <li v-for="(item,i) in list" :key="i" class="item">
        <div class="name">{{item.nickname}}</div>
        <div class="status" :class="{'gray':item.invite_status_text != '已下单'}">{{item.invite_status_text}}</div>
        <div class="word">{{item.award_text}}</div>
      </li>
    </ul>
    <h3 style="margin-top:-70px;" class="title">1000日元券包内容：</h3>
    <p class="content">100日元手续费券*2；满1000日元减200日元通用运费券*1；满5000日元减300日元商品费用券*2。<span class="gray">(上述优惠券仅限于任你购极速版app中使用，有效期一周，套券内容将随活动更新而更新。)</span></p>
    <h3 class="title">优惠券发放条件：</h3>
    <p class="content">受邀用户在注册后90天内下单，且下单商品状态更新为待配货状态。</p>
    <p class="content">老用户邀新券包会在达到上述状态后的24小时内发放。</p>
    <h3 class="title">注意事项：</h3>
    <p class="content">使用任你购极速版的用户均可参与活动，若出现扰乱规则和使用不正当手段（包括且不限于重复注册小号等方式）参与活动将会被永久取消邀新活动资格。</p>
    <footer class="footer">邀新有礼活动本公司<span class="bold">【株式会社三日月】</span>保留最终解释权</footer>
    <div v-if="visible" class="haibao">
      <div class="box">
        <img class="icon-close" src="@/assets/img/invite/close.png" @click="visible=false" />
        <img class="img" ref="img" :src="info.imgUrl" />
        <!-- <span class="code">{{info.msg}}</span> -->
        <div class="btn-save" @click="downloadFileByBase64(this.info.imgUrl,'邀新活动.png')"><img src="@/assets/img/invite/download.png" />保存海报</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {
        msg: '',
        imgUrl: '',
        qrCode: ''
      },
      visible: false,
      list: [],
      isIOS: false
    }
  },
  mounted() {
    const ua = window.navigator.userAgent;
    this.isIOS = !ua.includes("Android");
    sessionStorage.setItem('userToken', this.$route.query.token)
    sessionStorage.setItem('uid', this.$route.query.uid)
    this.getList()
    this.getCode()
  },
  methods: {
    getCode(){
      this.$axios.get(`user/index/myInviteCode`)
      .then(res=>{
        this.info.msg = res.data.invite_code
        this.info.qrCode = res.data.qr_code_url
        this.create()
      })
    },
    getList() {
      this.$axios.get(`user/index/myInviteHistory`,{
        params: {
          is_detail: 0
        }
      })
      .then(res=>{
        this.list = res.data
      })
    },
    copy() {
      if (this.isIOS) {
        window.webkit.messageHandlers.copy.postMessage({
          copyStr: this.info.msg
        })
      }
      const textarea = document.createElement('textarea')
      textarea.readOnly = 'readonly'
      textarea.style.position = 'absolute'
      textarea.style.left = '-9999px'
      textarea.value = this.info.msg
      document.body.appendChild(textarea)
      textarea.select()
      const result = document.execCommand('Copy')
      if (result) {
        window.uiCtl.toast('邀请码复制成功') // 可根据项目UI仔细设计
      }
      document.body.removeChild(textarea)
    },
    create() {
      const ratio = 2;
      const w = 325
      const h = 430
      const img = new Image()
      img.src = require('@/assets/img/invite/haibao.png')
      const canvas = document.createElement('canvas')
      canvas.width = w*ratio
      canvas.height = h*ratio
      canvas.style.width = `${w}px`
      canvas.style.height = `${h}px`
      const ctx = canvas.getContext('2d')
      img.onload = () => {
        ctx.drawImage(img,0,0,img.width,img.height,0,0,w*ratio,h*ratio)
        ctx.font = `${20*ratio}px 黑体`
        const x = 162 - this.info.msg.length*7
        ctx.fillText(this.info.msg, x*ratio, 300*ratio);
        const qrCode = new Image()
        qrCode.src = require('@/assets/img/invite/qrcode.png')
        qrCode.onload = () => {
          ctx.drawImage(qrCode,0,0,qrCode.width,qrCode.height,102*ratio,115*ratio,123*ratio,123*ratio)
          this.info.imgUrl = canvas.toDataURL("image/png")
        }
      }
    },
    dataURLtoFile(dataurl, filename) {//将base64转换为文件
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    downloadFile(url, name) {
      if (this.isIOS) {
        window.webkit.messageHandlers.saveImage.postMessage({
          imgData: this.info.imgUrl
        })
      } else {
        window.android.saveImage(this.info.imgUrl);
      }
      // const a = document.createElement("a");
      // a.setAttribute("href", url);
      // a.setAttribute("download", name);
      // a.setAttribute("target", "_blank");
      // const clickEvent = document.createEvent("MouseEvents");
      // clickEvent.initEvent("click", true, true);
      // a.dispatchEvent(clickEvent);
    },
    async downloadFileByBase64(base64, name) {
      const myBlob = await this.dataURLtoFile(base64);
      const myUrl = await URL.createObjectURL(myBlob);
      this.downloadFile(myUrl, name);
    },
    jump() {
      this.$router.push('/invite_new_record')
    }
  }
}
</script>
<style lang="scss" scoped>
.invite{
  width: 375px;
  margin: 0 auto;
  position: relative;
  background-color: #f6e6b5;
  .invite_bg{
    width: 100%;
    display: block;
  }
  .banner{
    position: absolute;
    top: 217px;
    width: 340px;
    height: 96px;
    z-index: 2;
    left: 50%;
    transform: translate(-50%);
  }
  .small-img{
    width: 102px;
    height: 134px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 416px;
    left: 44px;
  }
  .invite_code{
    position: absolute;
    top: 440px;
    right: 50px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #333;
    width: 147px;
    line-height: 22px;
    height: 22px;
    text-align: center;
  }
  .btn1,.btn2{
    position: absolute;
    top: 480px;
    width: 160px;
    height: 32px;
    z-index: 2;
    right: 42px;
  }
  .btn2{
    top: 523px;
  }
  .btn3{
    position: absolute;
    top: 840px;
    width: 110px;
    right: 37px;
    height: 27px;
  }
  .list{
    position: absolute;
    width: 310px;
    height: 120px;
    z-index: 2;
    top: 700px;
    left: 50%;
    transform: translate(-50%);
    .item{
      margin-top: 11px;
      display: flex;
      align-items: center;
      &>div{
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .name{
        width: 50px;
        flex-shrink: 0;
        margin-right: 15px;
        padding-left: 10px;
        line-height: 24px;
      }
      .status{
        width: 66px;
        flex-shrink: 0;
        height: 24px;
        background: #FFD11B;
        border-radius: 12px;
        color: #fff;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        margin-right: 15px;
        &.gray{  
          background: #A4A29A;
        }
      }
      .word{
        text-align: center;
      }
    }
  }
  .title{
    font-size: 14px;
    color: #333;
    font-weight: bold;
    line-height: 20px;
    padding: 0 30px;
    margin-top: 10px;
  }
  .content{
    padding: 0 30px;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    .gray{
      font-size: 12px;
      color: #625E57;
    }
  }
  .footer{
    margin-top: 20px;
    padding: 12px 30px;
    text-align: center;
    border-top: 1px solid #333;
    font-size: 12px;
    .bold{
      font-weight: bold;
    }
  }
  .haibao{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    z-index: 9;
    .box{
      position: relative;
      width: 325px;
      height: 528px;
      left: 50%;
      transform: translate(-50%);
      top: 74px;
      .icon-close{
        position: absolute;
        right: 0;
        top: 74px;
        width: 24px;
        height: 24px;
      }
      .img{
        top: 108px;
        left: 0;
        position: absolute;
        width: 325px;
        height: 430px;
        background: #EFEFEF;
        border-radius: 4px;
      }
      .code{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 300px;
      }
      .btn-save{
        position: absolute;
        top: 558px;
        left: 29px;
        width: 260px;
        height: 44px;
        background: #FF6A5B;
        border-radius: 22px;
        color: #fff;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        line-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>