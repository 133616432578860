const uiCtl = {
  toast(msg,time) {
    var dom = document.createElement('div')
    dom.style.cssText = `min-width: 166px;max-width: 300px;
      padding: 15px;
      border-radius: 4px;
      position: fixed;
      left: 50%;color:#fff;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0,0,0,0.7);
      text-align: center;z-index:99;`
    dom.innerText = msg || ''
    document.body.appendChild(dom)
    setTimeout(function(){
      document.body.removeChild(dom)
    }, time || 1500)
  }
}
window.uiCtl = uiCtl