export function htmlDecode(input: string): string {
  if (window.DOMParser) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent ?? '';
  }
  return input;
}

export function strip(html: any) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export const validateEmail = (email: string) => {
  return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email);
};

export const isMobile = (() => {
  const ua = (window?.navigator.userAgent ?? '').toLowerCase();
  return /android|iphone|ipad|ipod|ios/.test(ua);
})();
