<template>
  <div>
    <nav class="nav" @click="$router.back()"><img src="@/assets/img/invite/back.png" />邀请记录</nav>
    <div class="header">邀请记录只会保留近100天的数据，自受邀用户填写邀请码起算</div>
    <ul class="list">
      <li v-for="(item,i) in list" :key="i" class="item">
        <div class="left">
          <span class="name">{{item.nickname}}</span>
          <span class="status" :class="{'gray': item.invite_status_text != '已下单'}">{{item.invite_status_text}}</span>
        </div>
        <div class="right">
          <p class="content">{{item.award_text}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, ref, getCurrentInstance} from 'vue';

export default defineComponent({
  setup(){
    const {proxy} = getCurrentInstance();
    const list = ref([])
    const getList = ()=> {
      proxy.$axios.get(`user/index/myInviteHistory?is_detail=1`)
      .then(res=>{
        list.value = res.data
      })
    }
    getList()
    return {
      list,
      getList
    }
  }
})
</script>
<style lang="scss" scoped>
.nav{
  text-align: center;
  line-height: 42px;
  height: 42px;
  color: #333;
  position: relative;
  img{
    position: absolute;
    width: 12px;
    height: 12px;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header{
  padding: 15px 20px;
  background: #EEEEEE;
  font-size: 12px;
  color: #F14F46;
  text-align: left;
}
.item{
  padding: 14px 25px;
  border-bottom: 1px solid #eee;
  display: flex;
  &>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .left{
    text-align: left;
  }
  .right{
    text-align: center;
  }
  .name{
    font-size: 14px;
    color: #333333;
  }
  .status{
    margin-top: 4px;
    color: #FFB43B;
    font-size: 12px;
    &.gray{
      color: #ccc;
    }
  }
  .content{
    color: #999;
  }
}
</style>