const webview = {
  namespaced: true,
  state: () => ({
    statusBarHeight: 0,
    bottomSafetyHeight: 0,
    zoomRate: document.documentElement.clientWidth / 375
  }),
  mutations: {
    setWebviewInfo(state, { statusBarHeight = 0, bottomSafetyHeight = 0 }) {
      state.statusBarHeight = statusBarHeight || 0;
      state.bottomSafetyHeight = bottomSafetyHeight || 0;
    }
  },
  actions: {}
}

export default webview;