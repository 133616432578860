
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { useRoute } from 'vue-router'
import request from "@/utils/request";
export default defineComponent({
  setup(props, ctx) {
    const route = useRoute()
    const info = ref({})
    const getInfo = () => {
      request.get(`supplier/article/info/${route.params.id}`)
      .then((res:Object)=>{
        const { data } = res as any
        if(!data.img.includes('http')){
          data.img = `//${data.img}`
        }
        info.value = data
        window.document.title = data.title
      })
    }
    getInfo()
    const getTag = e => {
      if(e?.target?.className.includes('jump')){
        request.post(`supplier/product/jumpPage`,{
          site: e.target.dataset.sitename,
          product_id: e.target.dataset.id
        })
        .then((res:Object)=>{
          var ua = window.navigator.userAgent;
          var isIOS = !ua.includes("Android");
          if((res as any).data.jump_type == 'productDetails') {
            if (isIOS) {
              (window as any).webkit.messageHandlers.GoGoodDetail.postMessage({
                site: e.target.dataset.sitename,
                goodID: e.target.dataset.id
              })
            } else {
              (window as any).android.toDetail((res as any).data.jump_type,(res as any).data.product_link,e.target.dataset.id,e.target.dataset.sitename);
            }
          } else {
            if (isIOS) {
              (window as any).webkit.messageHandlers.GoRequest.postMessage({
                product_link: (res as any).data.product_link,
              })
            } else {
              (window as any).android.toDetail((res as any).data.jump_type,(res as any).data.product_link,e.target.dataset.id,e.target.dataset.sitename);
            }
          }
        })
        .catch(err=>{
          window.alert(err)
        })
      }
      function jsToOcFunction([funcName],res) {
        (window as any).webkit.messageHandlers[funcName].postMessage(res);
      }
    }
    return {
      info,
      getTag
    }
  },
})
