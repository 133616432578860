
import { defineComponent, ref, getCurrentInstance, onMounted } from 'vue';
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup() {
    const {proxy} = getCurrentInstance();
    const bg = ref(null)
    const imgUrl = ref('')
    onMounted(()=>{
      const { proxy } = getCurrentInstance() as any
      const route = useRoute()
      const ratio = 2;
      const dom = bg.value
      const w = dom.clientWidth
      const h = dom.clientHeight
      const img = new Image()
      img.src = require('@/assets/img/wechat_bg.png')
      const canvas = document.createElement('canvas')
      canvas.width = w
      canvas.height = h
      canvas.style.width = `${w}px`
      canvas.style.height = `${h}px`
      const ctx = canvas.getContext('2d')
      img.onload = () => {
        ctx.drawImage(img,0,0,img.width*ratio,img.height*ratio,0,0,w*ratio,h*ratio)
        proxy.$axios.get(`${proxy.$store.state.wechatUrl}/offiaccount/showQrCode`, {
          params: {
            token: route.query.token
          }
        })
        .then(res=>{
          const qrCode = new Image()
          qrCode.src = res.data.qr_code_base64
          qrCode.onload = () => {
            ctx.drawImage(qrCode,0,0,qrCode.width,qrCode.height,canvas.width*0.214,canvas.height*0.295,canvas.width * 0.58,canvas.width * 0.55)
            imgUrl.value = canvas.toDataURL("image/png")
          }
        })
        .catch(err=>{
          document.write(err)
        })
      }
    })
    
    return {
      imgUrl,
      bg
    }
  }
});
