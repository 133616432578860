import '@/assets/js/resize.js'
import '@/assets/js/common.js'
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import globalMethods from '@/assets/js/globalMethods.js'
import { getUserInfo } from '@/utils/bridge';
import Vconsole from 'vconsole'
process.env.NODE_ENV === 'development' && new Vconsole()

const userInfo = getUserInfo();
if (userInfo.token && userInfo.userId) {
  localStorage.setItem('userInfo', JSON.stringify({
    token: userInfo.token,
    user_id: userInfo.userId
  }));
}
// 获取状态栏高度和底部安全距离
if (window.URL) {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const webviewInfo = {
    statusBarHeight: 0,
    bottomSafetyHeight: 0
  }
  if (searchParams.get('statueHeight')) {
    webviewInfo.statusBarHeight = +searchParams.get('statueHeight') || 0;
  }
  if (searchParams.get('bottomSafeHeight')) {
    webviewInfo.bottomSafetyHeight = +searchParams.get('bottomSafeHeight') || 0;
  }
  store.commit('webview/setWebviewInfo', webviewInfo);
}


const app = createApp(App)
app.mixin({
  methods:{...globalMethods}
})
// const win: any = window // 
// if (process.env.NODE_ENV === 'development') {
//   if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in win) {
//     // 这里__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue赋值一个createApp实例
//     win.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app
//   }
// }
app.config.globalProperties.debounce = function (fun, delay) {
  return function (...args) {
      clearTimeout(fun.id)
      fun.id = setTimeout(() => {
          fun.call(this, args)
      }, delay)
  }
}

app.use(store).use(router).mount('#app');

