(function(doc, win) {
  var docEl = doc.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function() {
          var clientWidth = docEl.clientWidth;
          var photo = 375
          if(clientWidth > 375 && clientWidth <= 750) {
            photo = 375
          }
          if(clientWidth > 750 && clientWidth < 1440) {
            photo = 1440
          }
          if(clientWidth >= 1440) {
            photo = 1440
          }
          if (!clientWidth) return;
          docEl.style.fontSize = 100 * (clientWidth / photo) + 'px';
      };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);