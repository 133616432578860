import type { GoodsInfo } from '@/types/pool';
import api from '@/services/pool-api';

const pool = {
  namespaced: true,
  state: () => ({
    isSearchModalShow: false,
    isFilterModalShow: false,
    isMenuDrawerShow: false,
    isRequestModalShow: false,
    areaList: [],
    basicInfo: null
  }),
  getters: {
    hasOpenedModal(state) {
      return state.isSearchModalShow || state.isFilterModalShow
        || state.isRequestModalShow || state.applyModal.isShow
    }
  },
  mutations: {
    setSearchModalState(state, flag) {
      state.isSearchModalShow = flag;
    },
    setFilterModalState(state, flag) {
      state.isFilterModalShow = flag;
    },
    setMenuDrawerState(state, flag) {
      state.isMenuDrawerShow = flag;
    },
    setAreaListState(state, areaList) {
      state.areaList = areaList;
    },
    setBasicInfoState(state, basicInfo) {
      state.basicInfo = basicInfo;
    }
  },
  actions: {
    getAreaListAsync({ commit, state }) {
      if (state.areaList.length > 0) {
        return state.areaList;
      }
      return api.getAreaList().then(data => {
        commit('setAreaListState', data.areaList);
        return data.areaList;
      });
    },
    getBasicInfoAsync({ commit, state }) {
      if (state.basicInfo) {
        return state.basicInfo;
      }
      return api.getBasicInfo().then(data => {
        commit('setBasicInfoState', data);
        return data;
      });
    },
    closeAll({ commit }) {
      commit('setFilterModalState', false);
      commit('setSearchModalState', false);
      commit('setMenuDrawerState', false);
    },
    showSearchModal({ commit }) {
      commit('setSearchModalState', true);
    },
    hideSearchModal({ commit }) {
      commit('setSearchModalState', false);
    },
    showFilterModal({ commit }) {
      commit('setFilterModalState', true);
    },
    hideFilterModal({ commit }) {
      commit('setFilterModalState', false);
    },
    showMenuDrawer({ commit }) {
      commit('setMenuDrawerState', true);
    },
    hideMenuDrawer({ commit }) {
      commit('setMenuDrawerState', false);
    }
  },
  modules: {
    applyModal: {
      namespaced: true,
      state: () => ({
        isShow: false,
        userId: -1,
        poolId: -1,
        allowedStatus: 1, // 过滤商品状态，0:待审核,1:已审核
        goodsList: [] as Array<GoodsInfo>,
        message: '',
        userName: '',
        removeAction: false
      }),
      mutations: {
        setShowState(state, flag) {
          state.isShow = flag;
        },
        setModalInfo(state, params: {
          goodsList?: Array<GoodsInfo>,
          message?: string,
          userName?: string;
          userId: number;
          poolId: number;
        }) {
          state.goodsList = params.goodsList ?? [];
          state.message = params.message ?? '';
          state.userName = params.userName ?? '';
          state.userId = params.userId ?? -1;
          state.poolId = params.poolId ?? -1;
        }
      },
      actions: {
        syncModalData({ commit, state }, params) {
          const {
            poolId = state.poolId,
            userId = state.userId,
            allowedStatus = state.allowedStatus
          } = params;
          return api.listGoodsForApply({
            poolId,
            userId,
            allowedStatus
          }).then(data => {
            commit('setModalInfo', {
              goodsList: data.goodsList,
              message: data.message,
              userName: data.userName,
              poolId: params.poolId,
              userId: params.userId
            });
          });
        },
        showModal({ commit }) {
          commit('setShowState', true);
        },
        hideModal({ commit }) {
          commit('setModalInfo', {});
          commit('setShowState', false);
        }
      }
    }
  }
}

export default pool;